/******** ROOT *********/
html,
body {
	padding: 0;
	margin: 0;
	background-color: white !important;
}
a {
	color: var(--primary-color);
	text-decoration: none;
}
* {
	box-sizing: border-box;
}
:root {
	--primary-color: #273b80;
	--secondary-color: #f7964f;
	--secondary-color-light: rgba(247, 150, 79, 0.1);
	--bg-color: rgba(20, 159, 205, 0.1);
}

/***** FONTS *****/
@font-face {
	font-family: 'muli_regular';
	src: url('./assets/fonts/Muli.ttf');
}
@font-face {
	font-family: 'muli_medium';
	src: url('./assets/fonts/Muli-SemiBold.ttf');
}
@font-face {
	font-family: 'muli_bold';
	src: url('./assets/fonts/Muli-Bold.ttf');
}

.ql-editor {
	min-height: 100px;
}
